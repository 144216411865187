<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap">
      <!-- fiter -->
      <el-form label-position="top" label-width="100px" :model="filterModel">
        <tr>
          <td>
            <el-form-item class="mr-1" label="Дата начала">
              <x-date-picker
                v-model="filterModel.from_date"
                :clearable="false"
                :placeholder="$t('Дата начала')"
              />
            </el-form-item>
          </td>
          <td>
            <el-form-item class="mr-1" label="Дата окончания">
              <x-date-picker
                v-model="filterModel.to_date"
                :clearable="false"
                :placeholder="$t('Дата окончания')"
              />
            </el-form-item>
          </td>
          <td>
            <el-form-item class="mr-1" label=" ">
              <b-button
                variant="outline-primary"
                class="btn-tour-skip mr-1"
                @click="clearFilter"
              >
                <feather-icon icon="XIcon" size="16" />
              </b-button>
            </el-form-item>
          </td>
        </tr>
      </el-form>
      <!--  -->
      <b-button
        v-can="'contracts-excel-download'"
        variant="success"
        class="btn-tour-skip text-right mb-2"
        :disabled="xlLoading"
        @click="tableToExcel"
      >
        <feather-icon v-if="xlLoading" icon="LoaderIcon" size="16" />
        <feather-icon v-else icon="DownloadIcon" size="16" />
        Юклаб олиш
      </b-button>

      <b-button
        type="submit"
        variant="primary"
        class="mb-2"
        :disabled="!ids"
        @click="confirmAction"
      >
        To'lovlarni tasdiqlash
      </b-button>
    </div>

    <b-tabs>
      <b-tab
        v-for="tab in settingTabs"
        :key="tab.id"
        :title="tab.name"
        @click="onChange(tab)"
      >
        <b-overlay :show="loading">
          <good-table-selectable
            v-if="payments"
            ref="tablee"
            class="tablee"
            :total="payments.total"
            :page="page"
            :items="payments.data"
            :has-search="false"
            :columns="columns"
            :has-show="true"
            model="payment"
            :status="getTableStatus"
            :filter="filterModel"
            @selectedRows="selectedRows"
            @getItems="getItems"
            @onPageChange="(p) => (page = p)"
          />
        </b-overlay>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { showToast } from "@/utils/toast";
import GoodTableSelectable from "@/views/table/vue-good-table/GoodTableSelectable.vue";

export default {
  name: "Index",
  components: {
    GoodTableSelectable,
  },
  data() {
    return {
      page: 1,
      xlLoading: false,
      loading: false,
      status: 1,
      filterModel: {
        page: 1,
        per_page: 50,
        from_date: null,
        to_date: null,
        status: 1,
      },
      payments: null,
      settingTabs: [
        {
          id: 1,
          name: this.$t("Для подтверждения"),
        },
        {
          id: 2,
          name: this.$t("Одобренные"),
        },
      ],
      tabName: "",
      //
      uri: "data:application/vnd.ms-excel;base64,",
      template:
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta charset="utf-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64(s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      },
      format(s, c) {
        return s.replace(/{(\w+)}/g, (m, p) => c[p]);
      },
      ids: null,
    };
  },
  computed: {
    ...mapGetters({
      // payments: 'contract/GET_PAYMENT_ITEMS',
    }),
    getTableStatus() {
      if (this.filterModel.status == 1) return 0;
      return 1;
    },
    columns() {
      return [
        {
          label: this.$t("Дата"),
          isDate: true,
          field: "created_at",
        },
        {
          label: this.$t("Сумма"),
          field: "sum",
          isAmount: true,
        },
      ];
    },
    today() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const todays = today.toLocaleDateString();
      return todays.split(".").reverse().join("-");
    },
  },
  watch: {
    page(newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems();
    },
    "filterModel.per_page": function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems();
    },
    "filterModel.from_date": function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems();
    },
    "filterModel.to_date": function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems();
    },
  },
  mounted() {
    // this.filterModel.date = this.today
    this.getItems();
  },
  methods: {
    ...mapActions({
      getItemsAction: "contract/getDailyPayments",
      confirmPayment: "contract/confirmDailyPayments",
    }),
    onChange(tab) {
      this.filterModel.status = tab.id;
      this.tabName = tab.name;
      this.getItems();
    },
    selectedRows(rows) {
      this.ids = "";
      // const setIds = []
      if (rows && rows.length) {
        rows.forEach((el, index) => {
          this.ids += (index == 0 ? "" : ",") + el.ids;
        });
      }
    },
    confirmAction() {
      this.$bvModal
        .msgBoxConfirm(this.$t("To'lovni tasdiqlaysizmi?"), { centered: true })
        .then((value) => {
          if (value) {
            this.confirmation();
          }
        });
    },
    confirmation() {
      this.loading = true;
      this.confirmPayment({ ids: this.ids })
        .then(() => {
          this.getItems();
          this.ids = null;
          showToast("success", this.$t("Успешно сохранено"));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    searchFilter(search) {
      this.filterModel.search = search;
      this.getItems();
    },
    clearFilter() {
      this.filterModel.from_date = null;
      this.filterModel.to_date = null;
      this.filterModel.date = null;
      this.getItems();
    },
    tableToExcel() {
      setTimeout(() => {
        const table = document.querySelector(
          "div.tablee div.vgt-responsive table"
        );
        const ctx = {
          worksheet: this.fileName || "Ҳисоботлар",
          table: table.innerHTML,
        };
        const link = document.createElement("a");
        link.download = `${this.$route.meta.pageTitle}(${this.tabName}).xls`;
        link.href = this.uri + this.base64(this.format(this.template, ctx));
        link.click();
      }, 10);
    },
    async getItems() {
      this.loading = true;
      await this.getItemsAction({ ...this.filterModel, page: this.page }).then(
        (res) => {
          if (res.success) {
            this.payments = res.data;
          }
        }
      );
      this.loading = false;
    },
  },
};
</script>

<style scoped></style>
