<template>
  <b-card>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group v-if="hasSearch">
        <div class="d-flex align-items-center">
          <label class="mr-1">Qidirish</label>
          <b-form-input
            v-model="search"
            placeholder="Izlash"
            type="text"
            class="d-inline-block"
            @keyup.enter="$emit('search', search)"
          />
        </div>
      </b-form-group>
    </div>

    <div v-if="items && items.length">
      <vue-good-table
        ref="selectable-table"
        mode="remote"
        :columns="columns"
        :rows="items"
        :rtl="direction"
        :total="total"
        :search-options="searchOptions"
        :select-options="status&&(status==1 ||status==2)? { enabled: false} : selectOptions"
        :pagination-options="{
          enabled: true,
          perPage: filter.per_page
        }"
        @on-selected-rows-change="selectionChanged"
        @on-column-filter="onColumnFilter"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'row_number'">
            {{ getRowNumber(props.index) }}
          </span>
          <span v-if="props.column.field === 'confirmation'">
            <!-- {{ getRowNumber(props.index) }} -->
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
              @click="$emit('confirmAction', props.row.id)"
            >
              Tasdiqlash
            </b-button>
          </span>
          <span v-if="props.column.isAmount">
            {{ props.row[props.column.field] | formatPrice }}
          </span>
          <span v-else-if="props.column.showField && hasShow">
            <b-link
              v-if="props.formattedRow[props.column.field]"
              :to="{ name: model + '-show', params: { id: props.row.id } }"
            >{{ props.formattedRow[props.column.field] }}</b-link>
            <span v-else-if="props.row.bought_for_cash_client_full_name">{{ props.row.bought_for_cash_client_full_name }}</span>
            <span v-else>Naqdga sotilgan</span>
          </span>
          <span v-else-if="props.column.field === 'confirm_action'">
            <b-button
              v-if="props.row.status==0"
              type="submit"
              variant="primary"
              class="mb-2"
              @click="$emit('confirm', props.row.id)"
            >
              To'lovlarni tasdiqlash
            </b-button>
          </span>
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <template v-if="props.column.isContract">
                  <b-dropdown-item
                    v-if="hasEdit"
                    @click="goTo('OrderCreate', _, {pin:props.row.pin})"
                  >
                    <feather-icon
                      icon="FilePlusIcon"
                      class="mr-50"
                    />
                    <span>{{ $t('Создать договор') }}</span>
                  </b-dropdown-item>

                </template>
                <template v-if="props.column.isOrders">
                  <b-dropdown-item
                    v-if="hasEdit"
                    @click="$router.push({ name: model + '-show', params: { id: props.row.id } })"
                  >
                    <feather-icon
                      icon="FilePlusIcon"
                      class="mr-50"
                    />
                    <span>{{ $t('Создать оплата') }}</span>
                  </b-dropdown-item>

                </template>
                <template v-else>
                  <b-dropdown-item
                    v-if="hasEdit"
                    @click="$emit('edit', props.row)"
                  >
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>Tahrirlash</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="hasDelete"
                    @click="$emit('delete', props.row.id)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                    />
                    <span>O'chirish</span>
                  </b-dropdown-item>
                </template>
              </b-dropdown>
            </span>
          </span>
          <span v-else-if="props.column.field === 'status'">
            <b-badge
              v-if="props.row.status === 1"
              pill
              variant="light-success"
            >{{ $t('Активный') }}</b-badge>
            <b-badge
              v-if="props.row.status === 0"
              pill
              variant="light-warning"
            >{{ $t('Нективный') }}</b-badge>
          </span>
          <span v-else-if="props.column.isDate">
            {{ dateDotFormat(props.row[props.column.field]) }}
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing 1 to
              </span>
              <b-form-select
                v-model="filter.per_page"
                :options="['30','50','100']"
                class="mx-1"
                @input="(value)=> filter.per_page = value"
              />
              <span class="text-nowrap "> of {{ total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="total"
                :per-page="filter.per_page"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="onPageChange"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
        <div slot="emptystate">
          <div class="w-100 text-center">
            {{ $t('Маълумот йўқ') }}
          </div>
        </div>
      </vue-good-table>
    </div>
    <div v-else>
      <div class="w-100 text-center">
        {{ $t('Маълумот йўқ') }}
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BPagination, BFormSelect, BCard, BLink, BButton, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import store from '@/store/index'

export default {
  components: {
    BCard,
    BLink,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BButton,
    BDropdown,
    BDropdownItem,
  },
  props: {
    columns: {
      type: Array,
      default() {
        return []
      },
    },
    items: {
      type: Array,
      default() {
        return []
      },
    },
    filter: {
      type: Object,
      default() {
        return {}
      },
    },
    total: {
      type: Number,
      default() {
        return 0
      },
    },
    page: {
      type: Number,
      default() {
        return 1
      },
    },
    status: {
      type: Number,
      default() {
        return null
      },
    },
    modelName: {
      type: String,
      default() {
        return ''
      },
    },
    model: {
      type: String,
      default() {
        return ''
      },
    },
    hasShow: {
      type: Boolean,
      default() {
        return false
      },
    },
    hasEdit: {
      type: Boolean,
      default() {
        return true
      },
    },
    hasSearch: {
      type: Boolean,
      default() {
        return true
      },
    },
    hasDelete: {
      type: Boolean,
      default() {
        return true
      },
    },
  },
  data() {
    return {
      dir: false,
      search: '',
      selectOptions: {
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      },
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    searchOptions() {
      return {
        enabled: true,
        externalQuery: this.search,
      }
    },
  },
  methods: {
    onColumnFilter(params) {
      this.columns.filter(item => (item.filterOptions && item.filterOptions.enabled)).forEach(item => {
        let { field } = item
        if (typeof field === 'function') {
          field = item.filterField
        }
        if (item.filterOptions.filterDropdownItems) {
          const splitted = field.split('.')
          const newField = splitted.length === 1 ? splitted[0] : (`${splitted[0]}_id`)
          this.filter[newField] = params.columnFilters[field] ? params.columnFilters[field] : null
        } else {
          this.filter[field] = params.columnFilters[item.field] ? params.columnFilters[item.field] : null
        }
      })
      this.$emit('getItems')
    },
    onPageChange(page) {
      this.$emit('onPageChange', page)
    },
    selectionChanged(rows) {
      this.$emit('selectedRows', rows.selectedRows)
    },
    getRowNumber(index) {
      return ((this.page - 1) * this.filter.per_page) + index + 1
    },
  },
}
</script>
